import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Client Portal | Wavedropper
			</title>
			<meta name={"description"} content={"Pattern of Success,\nMapped for your Business"} />
			<meta property={"og:title"} content={"Client Portal | Wavedropper"} />
			<meta property={"og:description"} content={"Pattern of Success,\nMapped for your Business"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final-green.png?v=2024-04-24T16:57:01.241Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
		</Helmet>
		<Components.MessageProvider margin="-16px 0px 0px 0px">
			<Components.Login>
				<Override slot="topLeftLogo" />
				<Override slot="Sign out Button" />
				<Override slot="Signin Wrapper" />
				<Override slot="Google SignIn Button" />
				<Override slot="Sign In Main Title" />
				<Override slot="Signin Outer Wrapper" />
				<Override slot="Title Wrapper" color="--primary" />
				<Override slot="Sign In Descriptions" />
				<Override slot="Li11" />
				<Override slot="Li12" />
				<Override slot="Li13" />
				<Override slot="First list title" />
				<Override slot="First List" />
				<Override slot="Second list title" />
				<Override slot="Li21" />
				<Override slot="Li22" />
				<Override slot="Li23" />
				<Override slot="Second List" />
				<Override slot="Email Login Wrapper">
					<Override slot="Reset PW link" color="--primary" font="--base" cursor="pointer" />
					<Override slot="Sign Up link Wrapper" display="flex" justify-content="space-around" />
				</Override>
				<Override slot="Footer Wrapper" />
				<Override slot="Sign In Welcome" />
				<Override slot="Google SignIn Button Wrapper" />
				<Override slot="Google icon" />
				<Override slot="Email icon" />
				<Override slot="G Button Container" />
			</Components.Login>
		</Components.MessageProvider>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65f854bd8e2e8e002179c15d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});